import webAppInit from '@web-app/init';

import { mAjax, mCaptchaCreator, mPassword } from '@helpers';

const mWebApp = webAppInit();

jQuery(function (e) {
    mWebApp.authInit();

    let authHandleLogin = function (response) {
        if (response.back_url) {
            setTimeout(() => {
                location.href = response.back_url;
            }, 1000);
        } else if (response.url) {
            location.href = response.url;
        } else {
            setTimeout(() => {
                location.href = '';
            }, 1000);
        }
    };
    let authFormSubmitHandler = function (form) {
        mCaptchaCreator(function (token) {
            authFormCall(form, token);
        });
    };
    let authFormCall = function (form, token = null) {
        if (token) {
            jQuery(form).find(`[name="gtoken"]`).val(token);
        }

        mAjax.call(
            {
                formData: true,
                type: form.method,
                url: form.action,
                data: new FormData(form),
            },
            (success, response) => {
                if (success) {
                    authHandleLogin(response);
                }
            }
        );
    };
    let authErrorPlacement = function ($error, $element) {
        $element.closest('.form-group').append($error);
    };

    let frmLogin = jQuery('#frm-login');
    if (frmLogin.length) {
        frmLogin.validate({
            submitHandler: authFormSubmitHandler,
            errorPlacement: authErrorPlacement,
        });
    }

    let frmCLogin = jQuery('#frm-new-login');
    if (frmCLogin.length) {
        frmCLogin.validate({
            rules: {
                password: mPassword.policy(true),
            },
            submitHandler: authFormSubmitHandler,
            errorPlacement: authErrorPlacement,
        });
    }

    let frmCForgotPassword = jQuery('#frm-c-forgot-password');
    if (frmCForgotPassword.length) {
        frmCForgotPassword.validate({
            submitHandler: authFormSubmitHandler,
            errorPlacement: authErrorPlacement,
        });
    }

    let frmCResetPassword = jQuery('#frm-c-reset-password');
    if (frmCResetPassword.length) {
        frmCResetPassword.validate({
            rules: {
                password: mPassword.policy(true),
                password_confirmation: {
                    equalTo: '#new-password',
                },
            },
            submitHandler: authFormSubmitHandler,
            errorPlacement: authErrorPlacement,
        });
    }

    let frmCMfaAuth = jQuery('#frm-c-mfa-auth');
    if (frmCMfaAuth.length) {
        frmCMfaAuth.validate({
            submitHandler: authFormSubmitHandler,
            errorPlacement: authErrorPlacement,
        });
    }

    let frmCMfaRegister = jQuery('#frm-c-mfa-register');
    if (frmCMfaRegister.length) {
        frmCMfaRegister.validate({
            submitHandler: authFormSubmitHandler,
            errorPlacement: authErrorPlacement,
        });
    }

    let frmCMfaVerify = jQuery('#frm-c-mfa-verify');
    if (frmCMfaVerify.length) {
        frmCMfaVerify.validate({
            submitHandler: authFormSubmitHandler,
            errorPlacement: authErrorPlacement,
        });
    }

    let frmCPasswordSetup = jQuery('#frm-c-password-setup');
    if (frmCPasswordSetup.length) {
        frmCPasswordSetup.validate({
            rules: {
                password: mPassword.policy(true),
                password_confirmation: {
                    equalTo: '#new-password',
                },
            },
            submitHandler: authFormSubmitHandler,
            errorPlacement: authErrorPlacement,
        });
    }
});
